/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@font-face {
  font-family: 'BarlowCondensed';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/BarlowCondensed-Regular.ttf');
}

ion-content {
  --ion-background-color:var(--ion-color-pagebg);
  --ion-text-color:var(--ion-color-primary-contrast);
}

ion-toolbar {
  --color: var(--ion-color-primary);
  --background: var(--ion-color-pagebg);
  font-size: 36px;
  font-family: serif;
  font-style: italic;
  text-align: left;
  height: 55px;
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
}

.navbarNoBack {
  height: 48px !important;
  float: left;
  width: 15%;
}

.navbarBack {
  height: 48px !important;
  float: left;
  width: 15%;
}

.navbarMore {
  height: 48px !important;
  float: right;
  //width: 15%;
}

.navbarLogo {
  width: 70% !important;
  height: 38px !important;
  float: left !important;
  margin: 10px 0px 0px 0px;
  font-family: BarlowCondensed !important;
}

.navbarTitle {
  width: 70% !important;
  float: left !important;
  height: 48px !important;
  white-space: nowrap;
  font-family: BarlowCondensed !important;
  font-style: normal !important;
}

ion-text {
  --ion-background-color:var(--ion-color-primary-contrast);
}

.toolbar-title {
  text-align: left;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-left: 22px;
}

.text-input {
  border-bottom: 2px solid var(--ion-color-primary);
}

.tabs-md .tab-button[aria-selected=true] .tab-button-text {
  transform: none;
}

/*
.cbm-label {
  color: var(--ion-color-primary) !important;
  font-size: 20px !important;
  margin-bottom: 10px !important;
}

.cbm-input {
  background-color: white !important;
  color: black !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-height: 2.5rem;
}
*/

.cbm-stripe-input {
  background-color: white !important;
  color: black !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.cbm-error {
  color: yellow !important;
}

.cbm-margin-top {
  margin-top: 40px;
}

.cbm-margin-bottom {
   margin-bottom: 120px;
}

.cbm-margin-left-right {
  margin-left: 15px;
  margin-right: 15px;
}

.cbm-data-header {
  width: 100%;
  height: 50px;
}

p {
  font-family: Arial, serif;
  color: var(--ion-color-primary);
  text-align: center;
  font-size: 18px;
  padding: 10px;
}

.cbm-popover-info-auction {
  --width: 80%;
  --height: 60%;
  --background: var(--ion-color-pagebg);
}

ion-app.platform-ios12 ion-content { pointer-events: auto; /*fix scroll lock on ios12.2*/ }

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}